import React from "react";
import "./StayConnected.css";
import familyImage from "../assets/images/family_image.webp"
 // Adjust the path as necessary

const StayConnected = () => {
  return (
    <section className="stay-connected-section">
      <div className="stay-connected-content">
        <div className="text-content">
          <h2>Stay connected</h2>
          <p>
            Carry the best rates in your pocket. With no hidden fees, your money
            goes further when sending to Ethiopia. Enjoy fast transfers and
            check the latest rates anytime for easy sending.
          </p>
        </div>
        <div className="image-content">
          <img src={familyImage} alt="Family" />
        </div>
      </div>
    </section>
  );
};

export default StayConnected;
