import React from "react";
import "./Footer.css";
import logo from '../assets/png/White-logo-no-background.png'; // Adjust the path if necessary


const Footer = () => {
  return (
    <div className="footer-content-section">
    <footer className="footer">
     
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="Selam Exchange" />
        </div>
        <div className="footer-column">
          <h3>Company</h3>
          <ul>
            <li>
              <a href="#">About</a>
            </li>
            <li>
              <a href="#">Contact Us</a>
            </li>
            <li>
              <a href="#">Become an Agent</a>
            </li>
            <li>
              <a href="#">Currency Conversion</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Support</h3>
          <ul>
            <li>
              <a href="#">FAQ</a>
            </li>
            <li>
              <a href="#">File a Complaint</a>
            </li>
            <li>
              <a href="#">Error Resolution</a>
            </li>
            <li>
              <a href="#">Help Center</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Legal</h3>
          <ul>
            <li>
              <a href="#">Terms and Conditions</a>
            </li>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>©2024 Selam Exchange, Inc. All rights reserved</p>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
