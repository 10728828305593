import React, { useState } from "react";
import "./Header.css";
import logo from '../../assets/png/Color-logo-no-background.png'; // Adjust the path if necessary

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-top">
        <div className="burger" onClick={toggleMenu}>
          ☰
        </div>
        <div className="logo">
          <img src={logo} alt="Selam Exchange Logo" />
        </div>
      </div>
      <nav className={`navigation ${menuOpen ? "open" : ""}`}>
        <div className="nav-links">
          <a className="send-now" href="#send">Send now</a>
          <a className="track-transfer" href="#track">Track transfer</a>
          <a className="help" href="#help">Help</a>
        </div>
        <div className="dropdown">
          <button className="dropbtn">English ▼</button>
          <div className="dropdown-content">
            <a href="#english">English</a>
            <a href="#other">Other</a>
          </div>
        </div>
        <button className="login">Login</button>
        <button className="register">Register</button>
      </nav>
    </header>
  );
};

export default Header;
