import React from 'react';
import './HowToSendMoney.css';

const HowToSendMoney = () => {
  return (
    <div className="full-width-section-s">

    <section className="how-to-send-money-section">
      <h2>How to Send Money to Ethiopia</h2>
      <div className="steps">
        <div className="step-card">
          <div className="step-number">1</div>
          <h3>Create an account</h3>
          <p>Sign up with your email address or phone number by selecting the register button.</p>
        </div>
        <div className="step-card">
          <div className="step-number">2</div>
          <h3>Enter an amount and delivery method</h3>
          <p>You can choose either direct bank transfer or Telebirr.</p>
        </div>
        <div className="step-card">
          <div className="step-number">3</div>
          <h3>Enter receiver’s details</h3>
          <p>These details depend on the method you choose to send money.</p>
        </div>
        <div className="step-card">
          <div className="step-number">4</div>
          <h3>Choose a payment method</h3>
          <p>You can use one of our available payment methods, including debit card, credit card, or Apple Pay.</p>
        </div>
      </div>
    </section>
    </div>
  );
};

export default HowToSendMoney;
