import React from "react";
import "./FlexibleOptions.css";
import apple from "../assets/paymentIcons/apple-pay.png";
import amex from "../assets/paymentIcons/american-express.png";
import awash from "../assets/paymentIcons/awash.jpg";
import dashen from "../assets/paymentIcons/dashen-bank.png";
import mastercard from "../assets/paymentIcons/mastercard.png";
import paypal from "../assets/paymentIcons/paypal.png";
import telebirr from "../assets/paymentIcons/telebirr.png";
import commerical from "../assets/paymentIcons/commercial.jpeg";
import visa from "../assets/paymentIcons/visa.png";

const FlexibleOptions = () => {
  return (
    <div className="full-width-section-f">

    <section className="flexible-options-section">
      <h2>Flexible Payment and Delivery Options</h2>
      <div className="options-container">
        <div className="option-card">
          <h3>Payment Methods</h3>
          <div className="icons">
            <img className = "visa" src={visa} alt="Visa" />
            <img src={mastercard} alt="Mastercard" />
            <img src={amex} alt="American Express" />
            <img className = "apple" src={apple} alt="Apple Pay" />
            <img className="paypal" src={paypal} alt="PayPal" />
          </div>
        </div>
        <div className="arrow-container">
          <span className="arrow">➡️</span>
        </div>
        <div className="option-card">
          <h3>Delivery Options</h3>
          <div className="iconss">
            <img className="awash" src={awash} alt="Awash Bank" />
            <img src={commerical} alt="Commercial Bank of Ethiopia" />
            <img src={dashen} alt="Dashen Bank" />
            <img className="telebirr" src={telebirr} alt="Telebirr" />
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default FlexibleOptions;
