import React from 'react';
import './WhyChooseUs.css';

const WhyChooseUs = () => {
  return (
    <div className="full-width-section">
      <section className="why-choose-us">
        <h2>Why Choose Us</h2>
        <div className="reasons">
          <div className="reason-card">
            <div className="icon">⏱️</div>
            <h3>On Time Delivery</h3>
            <p>We guarantee timely delivery for every money transfer, or we will refund your fees.</p>
          </div>
          <div className="reason-card">
            <div className="icon">📝</div>
            <h3>Options</h3>
            <p>Choose the best delivery method for your recipient: bank account, mobile wallet, or cash pickup.</p>
          </div>
          <div className="reason-card">
            <div className="icon">🔒</div>
            <h3>Safe</h3>
            <p>Safety is our priority, ensuring secure and reliable money transfers every time.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUs;
