import React, { useState } from "react";
import "./Main.css";
import WhyChooseUs from "./WhyChooseUs";
import FlexibleOptions from "./FlexibleOptions";
import StayConnected from "./StayConnected";
import HowToSendMoney from "./HowToSendMoney";
import HelpSection from "./HelpSection";
import Footer from "./Footer";

const Main = () => {
  const [amount, setAmount] = useState(100.0);
  const [recipientAmount, setRecipientAmount] = useState(5691.87);
  const [paymentMethod, setPaymentMethod] = useState("Debit card");
  const [deliveryMethod, setDeliveryMethod] = useState("Cash pickup");
  const fee = 5.0;
  const totalToPay = amount + fee;

  return (
    <section className="main-section">
      <div className="section-one-wrapper">
        <div className="section-one">
          <div className="intro-text">
            <h1>
              Fast and safe <br /> money transfer <br /> to <span>Ethiopia</span>
            </h1>
          </div>
          <div className="mains-transfer-card">
            <div className="mains-form-group">
              <select className="mains-form-control">
                <option>Send to Ethiopia</option>
              </select>
            </div>

            <div className="amount-input-container">
              <label className="amount-label">You send</label>
              <div className="amount-input-group">
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  className="amount-input"
                />
                <div className="divider"></div>
                <div className="currency-display">
                  <div className="currency-circle"></div>
                  <span className="currency-text">USD</span>
                </div>
              </div>
            </div>

            <div className="amount-input-container">
              <label className="amount-label">Recipient receives</label>
              <div className="amount-input-group">
                <input
                  type="number"
                  value={recipientAmount}
                  readOnly
                  className="amount-input"
                />
                <div className="divider"></div>
                <div className="currency-display">
                  <div className="currency-circle"></div>
                  <span className="currency-text">ETB</span>
                </div>
              </div>
            </div>

            <div className="payment-section-container">
              <div className="form-group">
                <label className="label">Payment method</label>
                <select
                  className="form-control"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option>Debit card</option>
                </select>
              </div>

              <div className="form-group">
                <label className="label">Delivery method</label>
                <select
                  className="form-control"
                  value={deliveryMethod}
                  onChange={(e) => setDeliveryMethod(e.target.value)}
                >
                  <option>Cash pickup</option>
                </select>
              </div>

              <div className="form-group">
                <label className="label">Fee</label>
                <div className="value-container">
                  <p className="value">{fee.toFixed(2)}</p>
                  <p className="currency">USD</p>
                </div>
              </div>

              <div className="total-container">
                <div className="total-info">
                  <label className="total-label">Total to pay</label>
                  <p className="total-value">{totalToPay.toFixed(2)}</p>
                </div>
                <button className="mains-get-started">Get started</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WhyChooseUs />
      <HowToSendMoney />
      <FlexibleOptions />
      <StayConnected />
      <HelpSection />
      <Footer />
    </section>
  );
};

export default Main;
