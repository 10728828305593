import React from "react";
import "./HelpSection.css";

const HelpSection = () => {
  return (
    <section className="help-section">
      <div className="help-text">Need Help? Visit our Help Center</div>
      <div className="help-buttons">
        <button className="help-button">Email Us</button>
        <button className="help-button">Call Us</button>
      </div>
    </section>
  );
};

export default HelpSection;
